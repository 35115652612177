<template>
  <v-bottom-navigation
    app
    v-model="selectedAppBarTab"
  >
    <v-btn @click="showSidebar = !showSidebar">
      <v-icon>mdi-home</v-icon>
    </v-btn>
    <template v-for="tab in appBarTabs">
      <v-btn
        :key="tab.key"
        :value="tab.key"
      >
        <span>{{ $t(tab.title) }}</span>
      </v-btn>
    </template>
  </v-bottom-navigation>
</template>
<script>
export default {
    name: 'BottomNavigation',
    computed: {
        showSidebar: {
            set (newValue) {
                this.$store.commit("updateShowSidebar", newValue)
            },
            get: function () {
                return this.$store.state.showSidebar
            },
        },
				selectedAppBarTab: {
					set(value) {
						if(value === 0) {
							return
						}
						this.$store.commit('updateSelectedAppBarTab', value)
					},
					get() {
						return this.$store.state.selectedAppBarTab
					}
				},
				appBarTabs() {
					return this.$store.state.appBarTabs
				}
    }
}
</script>
